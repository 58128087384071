.ant-layout {
  @apply !bg-[#F9FAFB];
}

.ant-layout-content {
  @apply !bg-transparent !p-0;
}

.login-form .ant-form-item-label label {
  @apply !h-auto;
}

/* custom form */
.custom-form .ant-form-item-label label {
  @apply !text-sm !font-medium !text-header !h-auto !pb-1;
}

.custom-form .ant-form-item-required {
  @apply before:absolute before:right-0 before:top-0;
}

.custom-form.hide_star_mark .ant-form-item-label label {
  @apply before:!content-none;
}

.custom-form .ant-input-lg,
.custom-form .ant-picker-large {
  @apply !py-2.5 !px-3;
}
.custom-form .ant-input-affix-wrapper-lg .ant-input-lg,
.custom-form .ant-input-number-lg {
  @apply !py-0.5 !px-0;
}

.custom-form .ant-checkbox-group .ant-checkbox-wrapper span {
  @apply text-body select-none text-base font-normal;
}
.custom-form
  .ant-checkbox-group
  .ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner {
  @apply !bg-[#455468]  !border-[#455468];
}

.custom-form .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.custom-form .ant-checkbox-wrapper .ant-checkbox-checked:after {
  @apply !border-[#455468] !shadow-none;
}

.custom-form .ant-select-lg .ant-select-selection-placeholder,
.custom-form .ant-select-lg .ant-select-selector .ant-select-selection-item {
  @apply !leading-[2.6rem];
}

.custom-form .ant-input-affix-wrapper .ant-input-prefix,
.custom-form .ant-input-affix-wrapper .ant-input-suffix,
.custom-form .ant-select-suffix {
  @apply relative after:content-[''] after:absolute  after:top-2/4 after:-translate-y-2/4 after:w-[1px] after:bg-[#D7DFE9];
}
.custom-form .ant-input-affix-wrapper .ant-input-prefix {
  @apply !mr-4  after:-right-2.5 after:h-4;
}
.custom-form .ant-select-arrow .ant-select-suffix,
.custom-form .ant-input-affix-wrapper .ant-input-suffix {
  @apply after:-left-2.5 after:h-3.5;
}

.custom-form .ant-select .ant-select-selector input {
  @apply !pr-7;
}

.custom-form .ant-btn-lg,
.custom-form .ant-select-lg .ant-select-selector {
  @apply !h-11;
}

.custom-form .cancel_btn .ant-btn-default {
  @apply !bg-others-accent disabled:!bg-others-accent/50 disabled:!border-transparent hover:!bg-others-accent/90 !rounded-lg !text-white !text-base !border-others-accent !font-medium leading-7;
}

/* change input focuse color */
.custom-form .ant-input,
.custom-form .ant-input-affix-wrapper,
.custom-form .ant-input-number,
.custom-form .ant-select-selector,
.custom-form .ant-checkbox-inner {
  @apply border !border-solid !border-others-gray;
}

.custom-form .ant-input-status-error,
.custom-form .ant-input-affix-wrapper-status-error,
.custom-form .ant-input-number-status-error.ant-input-number,
.custom-form .ant-select-status-error .ant-select-selector,
.custom-form .ant-checkbox-inner-status-error {
  @apply border !border-solid !border-[#ff4d4f];
}

.custom-form .ant-input-affix-wrapper .ant-input {
  @apply !border-none;
}

.gray_btn .ant-btn-default {
  @apply !bg-[#D7DFE9] disabled:!bg-others-accent/50 disabled:!border-transparent hover:!bg-[#D7DFE9] !rounded-lg !text-body !text-base !border-[#D7DFE9] !font-medium leading-7;
}

.dark_btn.ant-btn-primary {
  @apply !bg-others-header hover:!bg-others-header/95;
}

button[type="submit"] span {
  @apply !text-white;
}

.ant-btn-primary {
  @apply bg-primary-np disabled:!bg-primary-np/70 hover:!bg-primary-np/90 !text-white font-medium;
}

.action-icons .ant-btn-icon {
  @apply !mt-[3.5px];
}

.ant-comment-inner {
  @apply !py-2;
}

.toast-message .ant-notification-notice-message {
  @apply !mb-0;
}

.ant-form-item-label {
  @apply !pb-1;
}

.ant-form-item-explain-error {
  @apply text-[12px];
}

.custom-search-input .ant-input {
  @apply pb-[7.5px];
}

.profile-menu .ant-dropdown-menu .ant-dropdown-menu-item {
  @apply px-0;
}

/* ====== sidebar menu ======> */
.custom-sidebar,
.custom-sidebar .sidebar-menu-item {
  @apply !bg-white border-r border-y-0 border-l-0 border-solid border-r-[#E9EFF6];
}

.sidebar-menu-item {
  @apply !px-4 !select-none;
}

.sidebar_collapsed {
  @apply !px-3;
}

.ant-menu-sub {
  @apply !bg-white !pl-6;
}

.ant-menu-inline .ant-menu-item {
  @apply !h-12 !mb-3;
}

.ant-menu-inline .ant-menu-submenu {
  @apply !mb-3;
}

.ant-menu-item,
.ant-menu-submenu {
  @apply !text-[#AFBACA] hover:!text-primary-np transition-colors;
}

.ant-menu-item,
.ant-menu-submenu-title {
  @apply !pl-3;
}

.ant-menu-title-content {
  @apply !transition-none !pl-2 !text-base !font-medium !text-[#5E718D] hover:!text-primary-np;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu .ant-menu-submenu-title {
  @apply !text-[#5E718D] hover:!text-primary-np;
}

.ant-menu-item-selected,
.ant-menu-item-selected .ant-menu-title-content,
.ant-menu-vertical .ant-menu-item.ant-menu-item-selected {
  @apply !text-white hover:!text-white;
}

.ant-menu-vertical .ant-menu-title-content {
  @apply !text-transparent hover:!text-transparent;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title {
  @apply !pl-3 !pt-2;
}

.ant-menu-vertical .ant-menu-submenu-title {
  @apply !pt-1.5;
}

.ant-menu-vertical .ant-menu-item {
  @apply !h-[42px] !mb-2;
}

.ant-menu-inline .ant-menu-submenu .ant-menu-sub .ant-menu-item {
  @apply !mb-0 !h-10;
}

/* dropdown popup */
.ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-title-content {
  @apply !text-primary-np;
}

.ant-menu-submenu-vertical.ant-menu-submenu-selected .ant-menu-submenu-title {
  @apply !text-primary-np;
}

.ant-menu-submenu-vertical .ant-menu-submenu-title .ant-menu-title-content {
  @apply !text-transparent;
}

.ant-menu-submenu-popup .ant-menu-vertical .ant-menu-item-active {
  @apply !transition-none;
}

.ant-menu-submenu-popup .ant-menu-item-selected .ant-menu-title-content {
  @apply !text-white hover:!text-white;
}

.ant-menu-submenu-popup .ant-menu-vertical {
  @apply !bg-white !shadow;
}

.ant-menu-submenu-popup .ant-menu-vertical {
  @apply !pl-0;
}

.ant-menu-submenu-popup .ant-menu-title-content {
  @apply !text-[#5E718D] hover:!text-primary-np;
}

.ant-menu-submenu-popup .ant-menu-item-selected {
  @apply !bg-primary-np !text-white !font-medium;
}

.ant-menu-submenu-popup .ant-menu-item {
  @apply !pt-[3px];
}

/* <====== sidebar menu ====== */

/* ====== table design ======> */
.ant-table .ant-table-cell {
  @apply !py-2 whitespace-nowrap;
}

.ant-table .ant-table-content {
  @apply overflow-x-auto;
}

.ant-table-thead tr th:first-child,
.ant-table-thead tr th:last-child {
  @apply !rounded-none;
}

.min-big-height .ant-table .ant-table-content {
  @apply !min-h-[calc(100vh-290px)];
}
.min-small-height .ant-table .ant-table-content {
  @apply !min-h-[calc(100vh-242px)];
}
/* 
.ant-pagination {
  @apply flex items-center;
}

.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-jump-next,
.ant-pagination .ant-pagination-item-ellipsis,
.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next {
  @apply text-[13px] font-medium text-primary-np disabled:text-gray-400 text-center bg-[#fafafa] rounded-md cursor-pointer flex justify-center items-center h-[34px] w-[34px] gap-1.5;
}

.ant-pagination .ant-pagination-item-active {
  @apply bg-[#eff2f7] border-none;
}

.ant-pagination .ant-pagination-item-active a {
  @apply !text-accent;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next {
  @apply w-auto px-3 rounded-md absolute;
}

.ant-pagination .ant-pagination-prev {
  @apply left-0;
}

.ant-pagination .ant-pagination-next {
  @apply right-0;
}

.ant-pagination .ant-pagination-disabled span {
  @apply text-gray-400;
}

.ant-pagination .ant-pagination-item-ellipsis {
  left: -11px !important;
  font-size: 10px;
} */

.custom_pagination .ant-pagination-item {
  @apply !bg-transparent !border-transparent;
}

.custom_pagination .ant-pagination-item a {
  @apply !text-base !font-medium !leading-[1.8rem] !text-[#8897AE];
}

.custom_pagination .ant-pagination-item-active a {
  @apply !text-others-info;
}

.custom_pagination .ant-pagination-prev.ant-pagination-disabled,
.custom_pagination .ant-pagination-next.ant-pagination-disabled {
  @apply !text-[#D7DFE9];
}

.custom_pagination .ant-pagination-prev,
.custom_pagination .ant-pagination-next {
  @apply !text-[#4A72FF];
}

/* edotco table */

.custom_edotco_table .ant-table-thead > tr th {
  @apply !bg-[#E9EFF6] !py-5 !text-header !text-base !font-medium !leading-normal first:!rounded-tl-lg last:!rounded-tr-lg;
}

.custom_edotco_table .ant-empty-normal {
  @apply !my-20;
}
.custom_edotco_table .ant-table-thead .ant-table-cell {
  @apply !px-2.5 !text-others-table-header !font-medium;
}
.custom_edotco_table .ant-table-tbody .ant-table-row .ant-table-cell {
  @apply !px-2.5 !py-3.5;
}
.custom_edotco_table .ant-table-tbody .ant-table-row .ant-table-cell,
.table_column_title h5 {
  @apply !text-header !text-sm !font-medium !leading-normal;
}
.table_column_title p {
  @apply !text-xs !leading-tight !tracking-tight !text-accent !font-normal;
}

.custom_edotco_table .ant-table-tbody tr .ant-table-cell {
  @apply !border-b-0;
}
.custom_edotco_table .ant-table-tbody tr:last-child .ant-table-cell {
  @apply !border-b;
}

.color_table tr:nth-child(even) td {
  @apply !bg-[#F9FAFB];
}
.color_table tr:nth-child(odd) td {
  @apply !bg-white;
}

/* <====== table design ====== */

/* ====== modal ======> */
.custom-modal .ant-modal-content {
  @apply !p-0;
}

.custom-modal .ant-modal-header {
  @apply p-5 bg-accent mb-0;
}

.custom-modal .ant-modal-header .ant-modal-title {
  @apply text-white text-[22px] font-semibold;
}

.custom-modal .ant-modal-close {
  @apply !top-[21px] !end-[47px];
}

.custom-modal .ant-modal-body {
  @apply !max-h-[550px] !overflow-y-auto;
}
/* <====== modal ====== */

/* ====== date range ======> */

.one-month-date-range
  .ant-picker-panels
  > *:first-child
  button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.one-month-date-range
  .ant-picker-panels
  > *:first-child
  button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.one-month-date-range .ant-picker-panels > *:last-child {
  display: none !important;
}

.one-month-date-range .ant-picker-panel-container,
.one-month-date-range .ant-picker-footer {
  width: 280px !important;
}

.one-month-date-range .ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}
/* <====== date range ====== */
.ant-breadcrumb-separator {
  @apply !mx-1;
}

/* comment area */
.application-comment .ant-comment-content-author {
  @apply items-center;
}

.fc-button-group button,
.fc-today-button {
  @apply !capitalize;
}

/* .notification .ant-comment-content-author {
  @apply mb-0;
} */

/* phone number with country input */
.phone_input .PhoneInputInput {
  /* border: none !important; */
  height: auto !important;
  touch-action: manipulation;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 8px;
  padding: 7px;
  color: #262626;
  @apply focus-visible:outline-none border border-solid border-gray-300 hover:border-primary-np;
}

/* Custom Rating */
.custom_rating .ant-rate-star {
  margin-inline-end: 4px !important ;
}

.custom_rating .ant-rate {
  @apply !text-sm text-others-rating;
}

.custom_rating .ant-rate-star-zero svg {
  @apply !text-others-gray;
}

.custom_range {
  @apply !m-0;
}

.custom_range .ant-slider-rail {
  @apply !bg-[#FFEBD8] !h-1.5 rounded-lg;
}
.custom_range .ant-slider-track {
  @apply !bg-others-rating !h-1.5 rounded-lg;
}

.custom_range.ant-slider .ant-slider-handle::after,
.custom_range.ant-slider .ant-slider-handle:hover::after {
  box-shadow: 0 0 0 3px #fc8415;
}

/* message */
.custom-message .ant-message-notice {
  @apply !flex !justify-end !pr-4;
}

/* Admin Layout */
.header_children .ant-picker,
.header_children .ant-btn,
.sub_header .ant-btn,
.sub_header .ant-picker,
.sub_header .ant-select-selector,
.sub_header .ant-select-selection-search,
.sub_header .ant-select-selection-search-input {
  @apply !h-9 !rounded-lg;
}

.header_children .ant-picker {
  @apply !border-primary-np;
}

.header_children .ant-picker-input input,
.header_children .ant-picker-suffix {
  @apply !text-sm !font-normal !text-header;
}

.header_children .ant-picker {
  @apply !w-[136px];
}

.header_children .date-range.ant-picker {
  @apply !min-w-[200px] !max-w-[230px] !w-auto;
}

.header_children .ant-picker-input input,
.sub_header .ant-select-selection-item,
.sub_header .ant-picker-input input,
.sub_header .ant-select-selection-placeholder {
  @apply !pt-0.5;
}

.dashboard .sub_header .ant-select-selector,
.dashboard .sub_header .ant-select .ant-select-arrow,
.dashboard .sub_header .title,
.dashboard .sub_header .ant-picker-input input {
  @apply !text-secondary-np !bg-transparent;
}

.sub_header .ant-select-selector,
.sub_header .ant-select .ant-select-arrow,
.sub_header .title,
.sub_header .ant-picker,
.sub_header .ant-picker-input input,
.sub_header .ant-picker-input .ant-picker-suffix {
  @apply !text-primary-np !bg-transparent;
}

.dashboard .sub_header .ant-select-selector,
.dashboard .sub_header .ant-picker {
  @apply !border-secondary-np !rounded-lg;
}

.sub_header .ant-select-selector,
.sub_header .ant-picker {
  @apply !border-primary-np !rounded-lg;
}
/* Admin Layout end */

/* Timeline start */
.ant-timeline .ant-timeline-item-head-custom {
  padding-block: 0px !important;
}
/* Timeline end */

/* Notification start */
.ant-notification .ant-notification-notice {
  @apply !p-3 !text-others-table-header;
}

.ant-notification .ant-notification-notice .ant-notification-notice-message {
  @apply !ml-8 !text-others-table-header !font-medium;
}
.ant-notification
  .ant-notification-notice
  .ant-notification-notice-description {
  @apply !ml-8 !text-body;
}
/* Notification end */

/* timeline */
.timeline_inactive .ant-timeline-item-tail {
  @apply !border-[#AFBACA];
}

.timeline_active.ant-timeline-item-last,
.timeline_inactive.ant-timeline-item-last {
  @apply !pb-0;
}

.accent-text.ant-btn-text {
  @apply !text-others-accent disabled:!text-others-accent/40;
}

.custom-search-input .ant-input {
  @apply !py-[6.2px] !border-primary-np;
}
.custom-search-input .ant-input-search-button {
  @apply !rounded-tl-none !rounded-bl-none !pt-0.5 hover:!border-primary-np !border-primary-np;
}

.highlight_placeholder .ant-input-number-input-wrap input::placeholder,
.highlight_placeholder .ant-picker-input input::placeholder {
  @apply !text-others-gray;
}

.sub_header .ant-input,
.sub_header .ant-input-group-addon button {
  @apply !bg-transparent;
}

.custom-upload .ant-upload {
  @apply !w-full;
}

.import_excel .ant-upload {
  @apply !border-none !p-0;
}
