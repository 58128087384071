.sa_panel .ant-btn-primary {
  @apply bg-primary-sa disabled:!bg-primary-sa/90 hover:!bg-primary-sa/90;
}

.sa_panel .ant-menu-item,
.sa_panel .ant-menu-submenu,
.sa_panel .ant-menu-title-content,
.sa_panel .ant-menu-vertical .ant-menu-item,
.sa_panel .ant-menu-vertical .ant-menu-submenu .ant-menu-submenu-title,
.sa_panel .ant-menu-submenu-popup .ant-menu-title-content {
  @apply hover:!text-primary-sa;
}

.sa_panel .ant-menu-submenu-selected .ant-menu-submenu-title,
.sa_panel
  .ant-menu-submenu-selected
  .ant-menu-submenu-title
  .ant-menu-title-content,
.sa_panel
  .ant-menu-submenu-vertical.ant-menu-submenu-selected
  .ant-menu-submenu-title,
.sa_panel .sub_header .ant-select-selector,
.sa_panel .sub_header .ant-select .ant-select-arrow,
.sa_panel .sub_header .title,
.sa_panel .sub_header .ant-picker,
.sa_panel .sub_header .ant-picker-input input,
.sa_panel .sub_header .ant-picker-input .ant-picker-suffix {
  @apply !text-primary-sa;
}

.sa_panel .ant-menu-submenu-popup .ant-menu-item-selected {
  @apply !bg-primary-sa;
}

.sa_panel .phone_input .PhoneInputInput {
  @apply hover:border-primary-sa;
}

.sa_panel .header_children .ant-picker,
.sa_panel .sub_header .ant-select-selector,
.sa_panel .sub_header .ant-picker {
  @apply !border-primary-sa;
}

.sa_panel .dashboard .sub_header .ant-select-selector,
.sa_panel .dashboard .sub_header .ant-select .ant-select-arrow,
.sa_panel .dashboard .sub_header .title,
.sa_panel .dashboard .sub_header .ant-picker-input input {
  @apply !text-secondary-sa;
}
.sa_panel .dashboard .sub_header .ant-select-selector,
.sa_panel .dashboard .sub_header .ant-picker,
.sa_panel .sub_header .ant-input,
.sa_panel .sub_header .ant-input-group-addon button {
  @apply !border-secondary-sa;
}

.sa_panel .ant-menu-item-selected.ant-menu-item-active svg,
.sa_panel .ant-menu-item-selected.ant-menu-item-active .ant-menu-title-content {
  @apply !text-white;
}

.sa_panel .ant-menu-vertical .ant-menu-title-content,
.sa_panel
  .ant-menu-submenu-vertical
  .ant-menu-submenu-title
  .ant-menu-title-content {
  @apply !text-transparent hover:!text-transparent;
}
/* 
.sa_panel .ant-menu-item-selected,
.sa_panel .ant-menu-item-selected .ant-menu-title-content,
.sa_panel .ant-menu-vertical .ant-menu-item.ant-menu-item-selected {
  @apply !bg-primary-sa;
} */

.sa_panel .ant-menu-submenu-popup .ant-menu-item .ant-menu-title-content,
.sa_panel .ant-menu-submenu-popup .ant-menu-item svg {
  @apply !text-header;
}

.sa_panel
  .ant-menu-submenu-popup
  .ant-menu-item-selected
  .ant-menu-title-content,
.sa_panel .ant-menu-submenu-popup .ant-menu-item-selected svg {
  @apply !text-white;
}

.sa_panel .secondary-btn {
  @apply !bg-secondary-sa hover:!bg-secondary-sa/90 !text-white;
}

.sa_panel .primary-text {
  @apply text-primary-sa;
}
